import { Roles } from 'app/models/roles';
import React, {
  Suspense,
  Fragment,
  lazy,
  LazyExoticComponent,
  FC
} from 'react';
import { Route } from 'react-router-dom';
import AuthGuard from './navigation/authGuards/AuthGuard';
import GuestGuard from './navigation/authGuards/GuestGuard';
import AppLayout from './navigation/AppLayout';
import LoadingScreen from './components/LoadingScreen';
import { Helmet } from 'react-helmet';
import AppClientLayout from 'navigation/AppClientLayout';

interface RouteType {
  guard?: (props: any) => JSX.Element;
  layout?: (props: any) => JSX.Element;
  component?: (props: any) => JSX.Element;
  routes?: RouteType[];
  path?: string;
  roles?: Roles[];
  title?: string;
}

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const NotFoundPage = Loadable(lazy(() => import('./pages/404')));
const UnauthorizedPage = Loadable(lazy(() => import('./pages/403')));
const LoginPage = Loadable(
  lazy(() => import('./pages/admin/authentication/Login'))
);
const ForgotPasswordPage = Loadable(
  lazy(() => import('./pages/admin/authentication/ForgotPassword'))
);
const ChangePassword = Loadable(
  lazy(() => import('./pages/admin/authentication/ChangePassword'))
);
const ResetPasswordPage = Loadable(
  lazy(() => import('./pages/admin/authentication/ResetPassword'))
);
const TenantListPage = Loadable(
  lazy(() => import('./pages/admin/tenants/TenantList'))
);
const UserListPage = Loadable(
  lazy(() => import('./pages/admin/users/UserList'))
);
const VenueListPage = Loadable(
  lazy(() => import('./pages/admin/venues/VenueList'))
);
const RimsListPage = Loadable(
  lazy(() => import('./pages/admin/rims/RimsList'))
);
const CarBrandListPage = Loadable(
  lazy(() => import('./pages/admin/carsBrand/CarBrandList'))
);
const CarModelListPage = Loadable(
  lazy(() => import('./pages/admin/carsModel/CarModelList'))
);
const EngineListPage = Loadable(
  lazy(() => import('./pages/admin/engines/EngineList'))
);
const CarListPage = Loadable(lazy(() => import('./pages/admin/cars/CarList')));
const RimListPage = Loadable(lazy(() => import('./pages/admin/rims/RimsList')));
const RimBrandListPage = Loadable(
  lazy(() => import('./pages/admin/rimBrands/RimBrandList'))
);
const RimColorListPage = Loadable(
  lazy(() => import('./pages/admin/rimColors/RimColorList'))
);
const RimShellListPage = Loadable(
  lazy(() => import('./pages/admin/rimShells/RimShellList'))
);
const PersonalizationPage = Loadable(
  lazy(
    () =>
      import(
        './pages/admin/personalizations/personalization/PersonalizationList'
      )
  )
);
const PrivacyPolicyList = Loadable(
  lazy(
    () =>
      import('./pages/admin/personalizations/privacyPolicy/PrivacyPolicyList')
  )
);

const AccountSettingsPage = Loadable(
  lazy(() => import('./pages/admin/profile/AccountSettings'))
);
const TenantsBrands = Loadable(
  lazy(
    () => import('./pages/admin/personalizations/TenantsBrands/TenantsBrands')
  )
);
const ContactList = Loadable(
  lazy(() => import('./pages/admin/personalizations/contact/ContactList'))
);
const UpdateRimsDataList = Loadable(
  lazy(
    () =>
      import('./pages/admin/personalizations/updateRimsData/UpdateRimsDataList')
  )
);

const HomePage = Loadable(
  lazy(() => import('./pages/client/homePage/HomePage'))
);

const RimDetails = Loadable(
  lazy(() => import('./pages/client/rimDetails/rimDetails'))
);

const RimList = Loadable(lazy(() => import('./pages/client/rim/RimList')));

const ARPage = Loadable(lazy(() => import('./pages/client/ARPage/ARPage')));

const PolicyPrivacy = Loadable(
  lazy(() => import('./pages/client/PolicyPrivacy/PolicyPrivacy'))
);

const Contact = Loadable(lazy(() => import('./pages/client/Contact/Contact')));

const Tutorial = Loadable(
  lazy(() => import('./pages/client/tutorial/Tutorial'))
);

const MyConfigurations = Loadable(
  lazy(() => import('./pages/client/MyConfigurations/MyConfigurations'))
);

const ModelViewer = Loadable(
  lazy(() => import('./pages/client/3DPage/ThreeDPage'))
);

const LanguageChoice = Loadable(
  lazy(() => import('./pages/client/LanguageChoice/Language'))
);
const LogoChange = Loadable(
  lazy(() => import('./pages/admin/personalizations/logo/LogoChange'))
);
const ExportCSV = Loadable(
  lazy(() => import('./pages/admin/exportCSV/ExportCSV'))
);

const ImageTest = Loadable(
  lazy(
    () =>
      import('./pages/client/ARPage/Components/SaveAndShare/ImageDisplay/Image')
  )
);

export const renderRoutes = (routes: RouteType[] = []) =>
  routes.map((route, i) => {
    const Guard = route.guard || React.Component;
    const Layout = route.layout || Fragment;
    const Component = route.component || React.Component;

    return (
      <Route
        key={i}
        path={route.path}
        element={
          <Fragment>
            {route.title && (
              <Helmet>
                <title>{route.title}</title>
              </Helmet>
            )}
            {route.guard ? (
              <Guard roles={route.roles}>
                <Layout>
                  <Component />
                </Layout>
              </Guard>
            ) : (
              <Layout>
                <Component />
              </Layout>
            )}
          </Fragment>
        }
      />
    );
  });

const appName = 'Felgeo';

const routes: RouteType[] = [
  {
    path: '/image/:id',
    component: ImageTest,
    title: `${appName} | Config`
  },
  {
    path: '/',
    component: HomePage,
    layout: AppClientLayout,
    title: `${appName} | Home`
  },
  {
    path: '/ar/:id',
    component: ARPage,
    title: `${appName} | AR`
  },
  {
    path: '/3d/:id',
    component: ModelViewer,
    title: `${appName} | 3D`
  },
  {
    path: '/rim',
    component: RimList,
    layout: AppClientLayout,
    title: `${appName} | Rim`
  },
  {
    path: '/rim-details/:id',
    component: RimDetails,
    layout: AppClientLayout,
    title: `${appName} | Rim Details`
  },
  {
    path: '/tutorial',
    component: Tutorial,
    title: `${appName} | Tutorial`
  },
  {
    path: '/contact',
    component: Contact,
    layout: AppClientLayout,
    title: `${appName} | Contact`
  },
  {
    path: '/policy-privacy',
    component: PolicyPrivacy,
    layout: AppClientLayout,
    title: `${appName} | Policy Privacy`
  },
  {
    path: '/my-configs',
    component: MyConfigurations,
    layout: AppClientLayout,
    title: `${appName} | My Configurations`
  },
  {
    path: '/language',
    component: LanguageChoice,
    layout: AppClientLayout,
    title: `${appName} | Language`
  },
  {
    path: '/404',
    component: NotFoundPage,
    layout: AppClientLayout,
    title: `${appName} | Not Found`
  },
  {
    path: '/403',
    component: UnauthorizedPage,
    layout: AppClientLayout,
    title: `${appName} | Unauthorized`
  },
  {
    path: '/login-admin',
    guard: GuestGuard,
    component: LoginPage,
    title: `${appName} | Login`
  },
  {
    path: '/login',
    component: LoginPage,
    title: `${appName} | Login`
  },
  {
    path: '/forgot-password',
    guard: GuestGuard,
    component: ForgotPasswordPage,
    title: `${appName} | Forgot Password`
  },
  {
    path: '/admin/change-password',
    guard: AuthGuard,
    component: ChangePassword,
    layout: AppLayout,
    title: `${appName} | Change Password`
  },
  {
    path: '/admin/export',
    guard: AuthGuard,
    component: ExportCSV,
    layout: AppLayout,
    title: `${appName} | Export CSV`
  },
  {
    path: '/reset-password',
    guard: GuestGuard,
    component: ResetPasswordPage,
    title: `${appName} | Reset Password`
  },
  {
    path: '/admin/rims',
    guard: AuthGuard,
    layout: AppLayout,
    component: RimsListPage,
    title: `${appName} | Rims`
  },

  {
    path: '/admin/cars',
    guard: AuthGuard,
    layout: AppLayout,
    component: CarListPage,
    title: `${appName} | Car list`
  },
  {
    path: '/admin/cars/brands',
    guard: AuthGuard,
    layout: AppLayout,
    component: CarBrandListPage,
    title: `${appName} | Car brands`
  },
  {
    path: '/admin/cars/engines',
    guard: AuthGuard,
    layout: AppLayout,
    component: EngineListPage,
    title: `${appName} | Car Engines`
  },
  {
    path: '/admin/cars/models',
    guard: AuthGuard,
    layout: AppLayout,
    component: CarModelListPage,
    title: `${appName} | Car models`
  },
  {
    path: '/admin/venues',
    guard: AuthGuard,
    layout: AppLayout,
    component: VenueListPage,
    title: `${appName} | Venues`
  },
  {
    path: '/admin/rims',
    guard: AuthGuard,
    layout: AppLayout,
    component: RimListPage,
    title: `${appName} |  Rim list`
  },
  {
    path: '/admin/rims/brands',
    guard: AuthGuard,
    layout: AppLayout,
    component: RimBrandListPage,
    title: `${appName} | Rim Brands`
  },
  {
    path: '/admin/rims/colors',
    guard: AuthGuard,
    layout: AppLayout,
    component: RimColorListPage,
    title: `${appName} | Rim color`
  },
  {
    path: '/admin/rims/shells',
    guard: AuthGuard,
    layout: AppLayout,
    component: RimShellListPage,
    title: `${appName} | Rim shell`
  },
  {
    path: '/admin/profile',
    guard: AuthGuard,
    component: AccountSettingsPage,
    title: `${appName} | Profile`,
    layout: AppLayout
  },
  {
    path: '/admin/personalization/update-data',
    guard: AuthGuard,
    component: UpdateRimsDataList,
    title: `${appName} | Update data`,
    layout: AppLayout
  },
  {
    path: '/admin/users',
    component: UserListPage,
    guard: AuthGuard,
    title: `${appName} | User Administration`,
    layout: AppLayout
  },
  {
    path: '/admin/tenants',
    component: TenantListPage,
    guard: AuthGuard,
    roles: [Roles.root, Roles.admin],
    title: `${appName} | Tenant Administration`,
    layout: AppLayout
  },
  {
    path: '/admin/personalization',
    component: PersonalizationPage,
    guard: AuthGuard,
    roles: [Roles.root, Roles.admin, Roles.editor],
    title: `${appName} | Personalization`,
    layout: AppLayout
  },
  {
    path: '/admin/personalization/privacy-policy',
    component: PrivacyPolicyList,
    guard: AuthGuard,
    roles: [Roles.root, Roles.admin, Roles.editor],
    title: `${appName} | Privacy Policy`,
    layout: AppLayout
  },
  {
    path: '/admin/personalization/rims-for-tenant',
    component: TenantsBrands,
    guard: AuthGuard,
    roles: [Roles.root, Roles.admin],
    title: `${appName} | Tenants Rim`,
    layout: AppLayout
  },
  {
    path: '/admin/personalization/contact',
    component: ContactList,
    guard: AuthGuard,
    roles: [Roles.root, Roles.admin, Roles.editor],
    title: `${appName} | Contact`,
    layout: AppLayout
  },
  {
    path: '/admin/personalization/logo',
    component: LogoChange,
    guard: AuthGuard,
    roles: [Roles.root, Roles.admin, Roles.editor],
    title: `${appName} | Logo`,
    layout: AppLayout
  },
  {
    path: '*',
    component: NotFoundPage
  }
];

export default routes;
