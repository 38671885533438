import { Roles } from 'app/models/roles';

const getRole = (id: string): Roles => {
  switch (id) {
    case 'basic':
      return Roles.basic;
    case 'editor':
      return Roles.editor;
    case 'admin':
      return Roles.admin;
    case 'root':
      return Roles.root;
    default:
      throw new Error('Invalid role');
  }
};

export default getRole;
