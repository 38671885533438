import * as THREE from 'three';
import agent from 'app/api/agent';
import appStore from '../appStore';
import modelStore from '../modelStore';
import { load2Dmodel, loadGlb } from 'app/utils/threeUtils';
import { Group } from 'three';
import { RimWithRelated } from 'app/models/rimCompact';

export const load = async (id: string) => {
  const rim = await agent.Rims.get(id);

  modelStore.setRim(rim.data);

  const isModelAvailable =
    rim.data.arModelFilePath !== null && rim.data.arModelFilePath.length > 0;
  const url = isModelAvailable
    ? rim.data.arModelFilePath
    : rim.data.imageFilePath;

  return await loadMesh(url, isModelAvailable);
};

const loadMesh = async (url: string, is3d: boolean) => {
  appStore.setIsLoading(true);

  const mesh = await loadModel(
    url,
    modelStore.state.selectedRim?.model!,
    is3d,
    false
  );

  const ghost = await loadModel(
    url,
    modelStore.state.selectedRim?.model!,
    is3d,
    true
  );

  appStore.setIsLoading(false);
  return [mesh, ghost];
};

const loadModel = async (
  url: string,
  model: string,
  is3d: boolean,
  isGhost: boolean
): Promise<THREE.Group> => {
  if (is3d) {
    return (await loadGlb(url, isGhost)).clone();
  }

  return (await load2Dmodel(url, model, isGhost)).clone();
};

const getModel = (rim: RimWithRelated): Promise<Group> => {
  const is3d = rim.arModelFilePath !== null && rim.arModelFilePath.length > 0;
  const url = is3d ? rim.arModelFilePath : rim.imageFilePath;

  return loadModel(url, rim.model, is3d, false);
};

export const getARRim = async (id: string): Promise<THREE.Group> => {
  const rim = await agent.Rims.get(id);
  const mesh = await getModel(rim.data);

  return mesh;
};
