import { makeAutoObservable, runInAction } from 'mobx';
import { nanoTheme } from 'theme';
import { Theme } from '@mui/material';

export default class ColorStore {
  colorA: string = '';
  colorB: string = '';
  colorC: string = '';

  theme: Theme = nanoTheme({
    direction: 'ltr',
    colorMode: 'dark',
    isResponsiveFontSizes: true
  });

  constructor() {
    makeAutoObservable(this);
  }

  setDefaultTheme = () => {
    this.theme = nanoTheme({
      direction: 'ltr',
      colorMode: 'dark',
      isResponsiveFontSizes: true
    });
  };

  setTheme(colora: string, colorb: string, colorc: string) {
    runInAction(() => {
      this.colorA = colora;
      this.colorB = colorb;
      this.colorC = colorc;

      const t = this.theme;
      t.palette.background.default = colora;
      t.palette.background.paper = colorb;
      this.theme = t;
      document.body.style.color = colorc;
    });
  }
}
