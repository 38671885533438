import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const lang = navigator.language.split('-')[0];
console.log(lang);

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: lang,
    fallbackLng: 'en',
    debug: true,
    backend: {
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
    },
    ns: ['common', 'ar', 'client']
  });
