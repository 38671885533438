import { Box, List, styled } from '@mui/material';
import { observer } from 'mobx-react-lite';
import NavCar from './NavCar';
import NavRim from './NavRim';
import NavTenant from './NavTenant';
import NavPersonalization from './NavPersonalization';
import * as React from 'react';
import DataUpdate from './DataUpdate';
import { useStore } from 'app/stores/store';

const MainMenu = styled(Box)(({ theme }) => ({
  width: 250,
  height: '100%',
  position: 'fixed',
  overflow: 'auto',
  left: 0,
  boxShadow: theme.shadows[2],
  zIndex: theme.zIndex.drawer + 11,
  transition: 'left 0.3s ease',
  backgroundColor: theme.palette.background.paper
}));

const SideBar = () => {
  const {
    currentUserStore: { currentUser }
  } = useStore();

  React.useEffect(() => {
    console.log(currentUser?.roleId);
  }, []);
  return (
    <MainMenu>
      <List sx={{ width: '100%', color: 'white' }}>
        <NavCar />
        <NavRim />
        {currentUser?.roleId !== 'basic' && <NavTenant />}
        {currentUser?.roleId !== 'basic' && <NavPersonalization />}
        <DataUpdate />
      </List>
    </MainMenu>
  );
};

export default observer(SideBar);
