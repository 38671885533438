import { Roles } from 'app/models/roles';
import Icons from '../../../icons/sidebar';
import { Sell } from '@mui/icons-material';

const navListTenant = [
  {
    title: 'navlist.users',
    Icon: Icons.UserProfileIcon,
    path: '/admin/users',
    roles: [Roles.root, Roles.admin, Roles.editor]
  },
  {
    title: 'navlist.tenants_sub',
    Icon: Icons.AccountSettingsIcon,
    path: '/admin/tenants',
    roles: [Roles.root, Roles.admin]
  },
  {
    title: 'navlist.tenant_brands',
    Icon: Sell,
    path: '/admin/personalization/rims-for-tenant',
    roles: [Roles.root, Roles.admin]
  }
];

export default navListTenant;
