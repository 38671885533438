import { Box, styled } from '@mui/material';
import React, { useEffect } from 'react';
import Navbar from './navBar/Navbar';
import SideBar from './sideBar/SideBar';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { useStore } from 'app/stores/store';
import { nanoTheme } from 'theme';

interface Props {
  children: React.ReactNode;
}

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: `calc(100% - 250px)`,
  paddingLeft: '3rem',
  paddingRight: '3rem',
  transition: 'all 0.3s',
  marginLeft: 250,
  [theme.breakpoints.down('md')]: {
    paddingLeft: '1rem',
    paddingRight: '1rem'
  }
}));

// Main layout with top bar and sidebar navigation
const AppLayout = (props: Props) => {
  const {
    colorStore: { setDefaultTheme }
  } = useStore();

  useEffect(() => {
    setDefaultTheme();
  }, []);

  return (
    <React.Fragment>
      <MuiThemeProvider theme={nanoTheme({})}>
        <ThemeProvider theme={nanoTheme({})}>
          <CssBaseline />
          <SideBar />
          <ContentWrapper>
            <Navbar />
            {props.children}
          </ContentWrapper>
        </ThemeProvider>
      </MuiThemeProvider>
    </React.Fragment>
  );
};

export default AppLayout;
