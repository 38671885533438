import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'material-react-toastify';
import agent from '../api/agent';
import { CurrentTenant } from 'app/models/currentTenant';
import { TenantBrand } from 'app/models/tenantBrands';
import { TenantRimBrand } from 'app/models/tenantRimBrand';

export default class CurrentTenantStore {
  currentTenants: CurrentTenant | null = null;
  loading = false;
  loadingInitial = false;
  currentTenantBrands: TenantBrand[] | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  // loading setter (initial page load)
  setLoadingInitial = (state: boolean) => {
    runInAction(() => {
      this.loadingInitial = state;
    });
  };

  // loading setter
  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };

  // load tenants
  loadCurrentTenants = async () => {
    this.setLoadingInitial(true);
    try {
      const result = await agent.CurrentTenants.list();
      runInAction(() => {
        this.currentTenants = result.data;
      });
      return this.currentTenants;
      // this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };

  // load tenant's brands
  loadTenantsBrands = async (id: string) => {
    this.setLoadingInitial(true);
    try {
      await this.loadCurrentTenants();
      const result = await agent.Tenants.brands(id);
      runInAction(() => {
        this.currentTenantBrands = result.data;
      });
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };

  updateCurrentTenantBrands = async (
    newBrands: TenantRimBrand
  ): Promise<boolean | undefined> => {
    this.setLoading(true);

    try {
      const response = await agent.CurrentTenants.updateBrands(newBrands);
      this.setLoading(false);
      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }
      toast.dark('Tenant brands updated!');
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };

  updateLogo = async (logo: File): Promise<boolean> => {
    try {
      const formData = new FormData();
      formData.append('file', logo);
      const responseLogo = agent.CurrentTenants.upload(formData);

      return true;
    } catch (err) {
      return false;
    }
  };

  getLogo = async (): Promise<string> => {
    try {
      this.setLoading(true);

      var path = await agent.CurrentTenants.logo();
      this.setLoading(false);
      return path.data;
    } catch (err) {
      this.setLoading(false);
      return '';
    }
  };

  updateCurrentTenant = async (
    currentTenant: CurrentTenant
  ): Promise<boolean | undefined> => {
    this.setLoading(true);

    if (currentTenant.logo) {
      const logo = new FormData();
      logo.append('file', currentTenant.logo);
      const responseLogo = await agent.CurrentTenants.upload(logo);
      currentTenant.logoPath = responseLogo.data;
    }

    try {
      const response = await agent.CurrentTenants.update(currentTenant);
      this.setLoading(false);
      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }
      runInAction(() => {
        this.currentTenants = currentTenant;
      });
      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };
}
