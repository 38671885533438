import { Construction, Garage, Sell, Style } from '@mui/icons-material';

const navListCar = [
  {
    title: 'navlist.car_list',
    Icon: Garage,
    path: '/admin/cars',
    roles: null
  },
  {
    title: 'navlist.car_makes',
    Icon: Style,
    path: '/admin/cars/models',
    roles: null
  },
  {
    title: 'navlist.engines',
    Icon: Construction,
    path: '/admin/cars/engines',
    roles: null
  },
  {
    title: 'navlist.car_brands',
    Icon: Sell,
    path: '/admin/cars/brands',
    roles: null
  }
];

export default navListCar;
