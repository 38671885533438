import Icons from '../../../icons/sidebar';
import { t } from 'i18next';
import {
  AlternateEmail,
  Policy,
  Sell,
  SystemUpdateAlt,
  Tune,
  Camera,
  InsertChart
} from '@mui/icons-material';

const navListPersonalization = [
  {
    title: 'navlist.settings',
    Icon: Tune,
    path: '/admin/personalization',
    roles: null
  },
  {
    title: 'navlist.logo',
    Icon: Camera,
    path: '/admin/personalization/logo'
  },
  {
    title: 'navlist.export_csv',
    Icon: InsertChart,
    path: '/admin/export'
  },
  {
    title: 'navlist.privacy',
    Icon: Policy,
    path: '/admin/personalization/privacy-policy',
    roles: null
  },
  {
    title: 'navlist.contact',
    Icon: AlternateEmail,
    path: '/admin/personalization/contact',
    roles: null
  }
];

export default navListPersonalization;
