import * as THREE from 'three';
import { MIN_SCALE, MAX_SCALE } from './arConst';

/**
 * Ends an XR session, if it exists.
 *
 * @param {XRSession} session - The XR session to shut down.
 */
export const shutdownXR = async (session: XRSession | null): Promise<void> => {
  if (session) {
    await session.end();
  }
};

/**
 * Extracts position, rotation, and scale arrays from a given matrix.
 *
 * @param {THREE.Matrix4} matrix - The matrix from which to extract the coordinate arrays.
 * @returns {[number[], number[], number[]]} - A tuple containing the position, rotation, and scale arrays respectively.
 */
export const getCoordArraysFromMatrix = (
  matrix: THREE.Matrix4
): [number[], number[], number[]] => {
  const newPosition = new THREE.Vector3();
  const newRotation = new THREE.Quaternion();
  const newScale = new THREE.Vector3();

  matrix.decompose(newPosition, newRotation, newScale);

  const eulerAngles = new THREE.Euler().setFromQuaternion(newRotation);

  return [
    newPosition.toArray(),
    eulerAnglesToArray(eulerAngles),
    newScale.toArray()
  ];
};

/**
 * Converts Euler angles to an array representation.
 *
 * @param {THREE.Euler} euler - The Euler angles to convert.
 * @returns {number[]} - An array representation of the Euler angles.
 */
export const eulerAnglesToArray = (euler: THREE.Euler): number[] => [
  euler.x,
  euler.y,
  euler.z
];

/**
 * Clamps a number between a minimum and maximum value.
 *
 * @param {number} num - The number to be clamped.
 * @param {number} min - The minimum value.
 * @param {number} max - The maximum value.
 * @returns {number} - The clamped number.
 */
export const clamp = (num: number, min: number, max: number): number =>
  Math.min(Math.max(num, min), max);

/**
 * Changes the scale of a THREE.js object uniformly.
 *
 * @param {THREE.Object3D} object - The object whose scale needs to be changed.
 * @param {number} scale - The scale value to be applied.
 */
export const changeScale = (object: THREE.Object3D, scale: number) => {
  const newScale = clamp(scale, MIN_SCALE, MAX_SCALE);

  object.scale.x = newScale;
  object.scale.y = newScale;
  object.scale.z = newScale;
};
