import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { RimSpacing } from '../models/rimSpacing';
import { SearchParams } from '../models/searchParams';
import { PaginatedResult } from '../models/responseWrappers';
import { toast } from 'material-react-toastify';

export default class RimSpacingStore {
  rimSpacings: RimSpacing[] = [];
  rimSpacingMetaData: Omit<PaginatedResult<RimSpacing>, 'data'> | null = null;

  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoadingInitial = (state: boolean) => {
    runInAction(() => {
      this.loadingInitial = state;
    });
  };
  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };
  setRimSpacingMetaData = (
    metaData: Omit<PaginatedResult<RimSpacing>, 'data'>
  ) => {
    runInAction(() => {
      this.rimSpacingMetaData = metaData;
    });
  };

  loadSpacing = async () => {
    this.setLoadingInitial(true);
    try {
      const result = await agent.RimSpacings.list();
      runInAction(() => {
        this.rimSpacings = result.data;
      });
      this.setLoadingInitial(false);
    } catch (err) {
      this.setLoadingInitial(false);
    }
  };

  loadRimSpacings = async (
    pageNumber: number = 1,
    pageSize: number = 5,
    orderByPriority: boolean = false,
    keyword: string = '',
    brand: string = ''
  ) => {
    this.setLoadingInitial(true);
    try {
      const params: SearchParams = {
        orderByPriority,
        pageNumber,
        pageSize,
        keyword,
        brand
      };
      const { data, ...metaData } = await agent.RimSpacings.search(params);
      runInAction(() => {
        this.rimSpacings = data;
      });
      this.setRimSpacingMetaData(metaData);
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };

  createRimSpacing = async (
    rimSpacing: RimSpacing
  ): Promise<boolean | undefined> => {
    this.setLoading(true);

    try {
      const rimSpacingRequestBody = {
        Name: rimSpacing.name
      };
      const response = await agent.RimSpacings.create(rimSpacingRequestBody);
      this.setLoading(false);
      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }
      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };

  updateRimSpacing = async (
    rimSpacing: RimSpacing
  ): Promise<boolean | undefined> => {
    this.setLoading(true);

    try {
      const response = await agent.RimSpacings.update(rimSpacing);
      this.setLoading(false);
      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }
      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };

  deleteRimSpacing = async (id: string) => {
    this.setLoadingInitial(true);

    try {
      await agent.RimSpacings.delete(id);
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };
}
