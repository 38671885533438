const JPG = '.jpg';
const PNG = '.png';
const ALPHA = 'alpha';

const getWebImageUrl = (rootPath: string): string => {
  return `${rootPath}\\image${JPG}`;
};

const getImageUrl = (rootPath: string): string => {
  return `${rootPath}\\image${PNG}`;
};

const getAlphaImageUrl = (rootPath: string): string => {
  return `${rootPath}\\image_${ALPHA}${PNG}`;
};

const getLogo = (tenant: string): string => {
  return `\\uploads\\Logos\\${tenant}.png`;
};

export default {
  getWebImageUrl,
  getImageUrl,
  getAlphaImageUrl,
  getLogo
};
