import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { RimBrand } from '../models/rimBrand';
import { SearchParams } from '../models/searchParams';
import { PaginatedResult } from '../models/responseWrappers';
import { toast } from 'material-react-toastify';
import { TenantBrand } from 'app/models/tenantBrands';

export default class RimBrandStore {
  rimBrands: RimBrand[] = [];
  rimBrandMetaData: Omit<PaginatedResult<RimBrand>, 'data'> | null = null;
  rimBrandsTenant: TenantBrand[] | null = null;

  loading = false;
  loadingInitial = false;

  queryString: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  setQueryString = (value: string) => {
    runInAction(() => {
      this.queryString = value;
    });
  };

  setLoadingInitial = (state: boolean) => {
    runInAction(() => {
      this.loadingInitial = state;
    });
  };
  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };
  setRimBrandMetaData = (metaData: Omit<PaginatedResult<RimBrand>, 'data'>) => {
    runInAction(() => {
      this.rimBrandMetaData = metaData;
    });
  };

  loadRimBrandsForTenant = async () => {
    this.setLoadingInitial(true);
    try {
      const result = await agent.RimBrands.get();

      runInAction(() => {
        this.rimBrandsTenant = result.data;
      });
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };

  loadRimBrands = async (
    pageNumber: number = 1,
    pageSize: number = 5,
    keyword: string = '',
    orderByPriority: boolean = false,
    brand: string = ''
  ) => {
    this.setLoadingInitial(true);
    try {
      const params: SearchParams = {
        orderByPriority,
        pageNumber,
        pageSize,
        keyword,
        brand
      };
      const { data, ...metaData } = await agent.RimBrands.search(params);
      runInAction(() => {
        this.rimBrands = data;
      });
      this.setRimBrandMetaData(metaData);
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };

  createRimBrand = async (rimBrand: RimBrand): Promise<boolean | undefined> => {
    this.setLoading(true);

    try {
      const rimBrandRequestBody = {
        Name: rimBrand.name
      };
      const response = await agent.RimBrands.create(rimBrandRequestBody);
      this.setLoading(false);
      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }
      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };

  updateRimBrand = async (rimBrand: RimBrand): Promise<boolean | undefined> => {
    this.setLoading(true);

    try {
      const response = await agent.RimBrands.update(rimBrand);
      this.setLoading(false);
      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }
      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };

  deleteRimBrand = async (id: string) => {
    this.setLoadingInitial(true);

    try {
      await agent.RimBrands.delete(id);
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };
}
