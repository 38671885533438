import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { RimColor } from '../models/rimColor';
import { SearchParams } from '../models/searchParams';
import { PaginatedResult } from '../models/responseWrappers';
import { toast } from 'material-react-toastify';

export default class RimColorStore {
  rimColors: RimColor[] = [];
  rimColorMetaData: Omit<PaginatedResult<RimColor>, 'data'> | null = null;

  loading = false;
  loadingInitial = false;

  queryString: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  setQueryString = (value: string) => {
    runInAction(() => {
      this.queryString = value;
    });
  };

  setLoadingInitial = (state: boolean) => {
    runInAction(() => {
      this.loadingInitial = state;
    });
  };
  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };
  setRimColorMetaData = (metaData: Omit<PaginatedResult<RimColor>, 'data'>) => {
    runInAction(() => {
      this.rimColorMetaData = metaData;
    });
  };

  loadRimColors = async (
    pageNumber: number = 1,
    pageSize: number = 200,
    keyword: string = '',
    orderByPriority: boolean = false,
    brand: string = ''
  ) => {
    this.setLoadingInitial(true);
    try {
      const params: SearchParams = {
        orderByPriority,
        pageNumber,
        pageSize,
        keyword,
        brand
      };
      const { data, ...metaData } = await agent.RimColors.search(params);
      runInAction(() => {
        this.rimColors = data;
      });
      this.setRimColorMetaData(metaData);
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };

  createRimColor = async (rimColor: RimColor): Promise<boolean | undefined> => {
    this.setLoading(true);

    try {
      const rimColorRequestBody = {
        Name: rimColor.name
      };
      const response = await agent.RimColors.create(rimColorRequestBody);
      this.setLoading(false);
      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }
      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };

  updateRimColor = async (rimColor: RimColor): Promise<boolean | undefined> => {
    this.setLoading(true);

    try {
      const response = await agent.RimColors.update(rimColor);
      this.setLoading(false);
      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }
      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };

  deleteRimColor = async (id: string) => {
    this.setLoadingInitial(true);

    try {
      await agent.RimColors.delete(id);
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };
}
