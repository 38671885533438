import * as THREE from 'three';
import { makeAutoObservable, runInAction } from 'mobx';
import { ARRim } from './model/ARRim';
import { RimWithRelated } from 'app/models/rimCompact';

interface IModelState {
  areWheelsSpawnable: boolean;
  ghostVisible: boolean;
  modelsVisible: boolean;
  modelMesh: THREE.Group | null;
  ghostMesh: THREE.Group | null;
  defaultScale: number;
  currentlyChosenRim: ARRim | null;
  spawnedModels: ARRim[];
  selectedRim: RimWithRelated | null;
  scaleModifier: number;
}

const initialState: IModelState = {
  areWheelsSpawnable: true,
  modelsVisible: true,
  modelMesh: null,
  ghostMesh: null,
  ghostVisible: true,
  defaultScale: 18,
  currentlyChosenRim: null,
  spawnedModels: [],
  selectedRim: null,
  scaleModifier: 1
};

class ModelStore {
  state: IModelState = initialState;

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    runInAction(() => {
      this.state = initialState;
    });
  }

  addSpawnedModels(model: ARRim) {
    runInAction(() => {
      this.state.spawnedModels = [...this.state.spawnedModels, model];
    });
  }

  setGhostVisible(value: boolean) {
    runInAction(() => {
      this.state.ghostVisible = value;
    });
  }

  toggleModelVisibility() {
    runInAction(() => {
      this.state.modelsVisible = !this.state.modelsVisible;
    });
  }

  setModels(model: THREE.Group, ghost: THREE.Group) {
    runInAction(() => {
      this.state.modelMesh = model;
      this.state.ghostMesh = ghost;
    });
  }

  setRim(rim: RimWithRelated) {
    runInAction(() => {
      this.state.selectedRim = rim;
    });
  }

  setWheelsSpawnable(value: boolean) {
    runInAction(() => {
      this.state.areWheelsSpawnable = value;
    });
  }

  resetSpawnedRims() {
    runInAction(() => {
      this.state.spawnedModels = [];
    });
  }

  setPosition(position: THREE.Vector3) {
    runInAction(() => {
      this.state.currentlyChosenRim!.rootPosition! = position;
    });
  }

  setPositionOffset(position: THREE.Vector3) {
    const element = this.state.currentlyChosenRim?.element;
    if (!element) {
      console.warn('no element');
    }

    const rootPosition = this.state.currentlyChosenRim?.rootPosition.clone()!;
    const newPosition = new THREE.Vector3(
      rootPosition.x + position.x,
      rootPosition.y + position.y,
      rootPosition.z + position.z
    );

    runInAction(() => {
      this.state.currentlyChosenRim!.positionOffset! = position;
      this.state.currentlyChosenRim!.element!.props.position = [
        newPosition.x,
        newPosition.y,
        newPosition.z
      ];
    });
  }
}

const modelStore = new ModelStore();
export default modelStore;
