import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { CarModel, SearchCarModelData } from '../models/carModel';
import { SearchParams } from '../models/searchParams';
import { PaginatedResult } from '../models/responseWrappers';
import { toast } from 'material-react-toastify';

export default class CarModelStore {
  carModels: CarModel[] = [];
  carModelMetaData: Omit<PaginatedResult<CarModel>, 'data'> | null = null;
  searchCarModelData: SearchCarModelData[] = [];

  loading = false;
  loadingInitial = false;

  queryString: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  setQueryString = (value: string) => {
    runInAction(() => {
      this.queryString = value;
    });
  };

  // loading setter (initial page load)
  setLoadingInitial = (state: boolean) => {
    runInAction(() => {
      this.loadingInitial = state;
    });
  };
  // loading setter
  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };
  // set pagination meta data
  setCarModelMetaData = (metaData: Omit<PaginatedResult<CarModel>, 'data'>) => {
    runInAction(() => {
      this.carModelMetaData = metaData;
    });
  };

  loadCarModels = async (
    pageNumber: number = 1,
    pageSize: number = 5,
    keyword: string = '',
    brand: string = '',
    orderByPriority: boolean = false
  ) => {
    this.setLoadingInitial(true);
    try {
      const params: SearchParams = {
        orderByPriority,
        pageNumber,
        pageSize,
        keyword,
        brand
      };
      const { data, ...metaData } = await agent.CarModels.search(params);
      runInAction(() => {
        this.carModels = data;
      });
      this.setCarModelMetaData(metaData);
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };

  createCarModel = async (carModel: CarModel): Promise<boolean | undefined> => {
    this.setLoading(true);

    try {
      const carModelRequestBody = {
        Name: carModel.name,
        BrandId: carModel.brandId
      };
      const response = await agent.CarModels.create(carModelRequestBody);
      this.setLoading(false);
      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }
      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };

  updateCarModel = async (carModel: CarModel): Promise<boolean | undefined> => {
    this.setLoading(true);

    try {
      const response = await agent.CarModels.update(carModel);
      this.setLoading(false);
      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }
      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };

  deleteCarModel = async (id: string) => {
    this.setLoadingInitial(true);

    try {
      await agent.CarModels.delete(id);
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };
}
