import { List, ListItemButton } from '@mui/material';
import ScrollBar from 'simplebar-react';
import navListCar from './constants/navListCar';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { NavTypography } from '../../components/Typography';
import { useTranslation } from 'react-i18next';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import * as React from 'react';
import { DirectionsCar } from '@mui/icons-material';

const navCar = () => {
  const { t } = useTranslation(['common']);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <DirectionsCar />
        </ListItemIcon>
        <ListItemText primary={t('navlist.cars')} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <ScrollBar style={{ maxHeight: 'calc(100% - 50px)' }}>
        {navListCar.map((nav, index) => (
          <Fragment key={index}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  disableRipple
                  onClick={() => navigate(nav.path)}
                >
                  <nav.Icon
                    sx={{
                      color: 'white'
                    }}
                  />
                  <NavTypography mb={2} sx={{ color: 'white' }}>
                    {t(nav.title)}
                  </NavTypography>
                </ListItemButton>
              </List>
            </Collapse>
          </Fragment>
        ))}
      </ScrollBar>
    </>
  );
};

export default observer(navCar);
