import { List, ListItemButton } from '@mui/material';
import ScrollBar from 'simplebar-react';
import navListRim from './constants/navListRim';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { NavTypography } from '../../components/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Toll } from '@mui/icons-material';
import { useStore } from 'app/stores/store';
import getRole from 'app/utils/roleUtils';

const navRim = () => {
  const { t } = useTranslation(['common']);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    currentUserStore: { currentUser }
  } = useStore();

  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  const listFiltered = navListRim.filter((nav) =>
    nav!.roles!.includes(getRole(currentUser!.roleId))
  );

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <Toll />
        </ListItemIcon>
        <ListItemText primary={t('navlist.rims')} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <ScrollBar style={{ maxHeight: 'calc(100% - 50px)' }}>
        {listFiltered.map((nav, index) => (
          <Fragment key={index}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  disableRipple
                  onClick={() => navigate(nav.path)}
                >
                  <nav.Icon
                    sx={{
                      color: 'white'
                    }}
                  />
                  <NavTypography mb={2} sx={{ color: 'white' }}>
                    {t(nav.title)}
                  </NavTypography>
                </ListItemButton>
              </List>
            </Collapse>
          </Fragment>
        ))}
      </ScrollBar>
    </>
  );
};

export default observer(navRim);
