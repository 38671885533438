import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'material-react-toastify';
import { store } from '../stores/store';
import {
  TokenData,
  UserLogin,
  ForgotPasswordRequest,
  ResetPasswordRequest
} from '../models/auth';
import { User, RegisterUserRequest } from '../models/user';
import {
  ChangePasswordRequest,
  UpdateProfileRequest,
  UpdatePreferencesRequest,
  CurrentUser
} from '../models/currentUser';
import { SearchParamsRims } from 'app/models/searchParamsRims';
import { SearchParams } from '../models/searchParams';
import { PaginatedResult, Result } from '../models/responseWrappers';
import { CreateTenantRequest, Tenant } from '../models/tenant';
import { AddVenueRequest, Venue } from '../models/venue';
import { AddCarRequest, Car } from '../models/car';
import { AddCarBrandRequest, CarBrand } from '../models/carBrand';
import { AddEngineRequest, Engine } from '../models/engine';
import { AddCarModelRequest, CarModel } from '../models/carModel';
import { AddRimBrandRequest, RimBrand } from 'app/models/rimBrand';
import { AddRimRequest, Rim, UpdateRimRequest } from 'app/models/rim';
import { AddRimColorRequest, RimColor } from 'app/models/rimColor';
import { CompatibilityRequest } from 'app/models/compatibilityRequest';
import { AddRimShellRequest, RimShell } from 'app/models/rimShell';
import { AddRimSpacingRequest, RimSpacing } from 'app/models/rimSpacing';
import { PrivacyPolicy } from 'app/models/privacyPolicy';
import { CurrentTenant } from 'app/models/currentTenant';
import { TenantRimBrand } from 'app/models/tenantRimBrand';
import { TenantBrand } from 'app/models/tenantBrands';
import { SearchParamsCars } from '../models/searchParamsCars';
import { RimWithRelated } from 'app/models/rimCompact';
import { RimByCarRequest } from 'app/models/rimByCar';
import { CSV } from 'app/models/csv';
import { Contact } from 'app/models/contact';
import { t } from 'i18next';
import { FindCarRequest } from 'app/models/findCarRequest';
import { UpdateTenantBasic } from 'app/models/tenantBasic';

// Base URL: https://localhost:7250/api for dev, or your production api url
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// Send up the token with every request, when there is a token
axios.interceptors.request.use((config) => {
  const token = store.commonStore.token;

  config.headers = {
    Tenant: store.commonStore.tenant ?? ''
  };

  if (token) config.headers.Authorization = `Bearer ${token}`;

  return config;
});

// Axios reponse interceptors
axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error: AxiosError) => {
    const { data, status } = error.response!;

    switch (status) {
      case 400:
        toast.error(t('errors.400'));
        break;
      case 401:
        toast.error(t('errors.401'));
        store.currentUserStore.logout();
        break;
      case 500:
        toast.error(t('errors.500'));
        break;
    }
    return Promise.reject(error);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

// Axios Base
const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}, options?: {}) =>
    axios.post<T>(url, body).then(responseBody),
  multipart: async <T>(url: string, body: {}) => {
    return axios
      .post<T>(url, body, {
        headers: { 'Content-type': 'multipart/form-data' }
      })
      .then(responseBody);
  },
  put: <T>(url: string, body: {}, options?: {}) =>
    axios.put<T>(url, body, options).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody)
};

// Auth / Profile Management (Current User)
const Account = {
  current: () => requests.get<Result<CurrentUser>>('/identity/profile'),
  login: (user: UserLogin) => requests.post<Result<TokenData>>(`/tokens`, user),
  update: (user: UpdateProfileRequest) =>
    requests.put<Result<CurrentUser>>(`/identity/profile`, user),

  updateProfile: (user: UpdateProfileRequest) => {
    let formData = new FormData(); // Form data so that we can include the image file

    Object.entries(user).forEach(([key, val]) => {
      formData.append(key, val);
    });

    return requests.put<Result<CurrentUser>>('/identity/profile', formData, {
      headers: { 'Content-type': 'multipart/form-data' }
    });
  },
  updatePreferences: (updatePreferencesRequest: UpdatePreferencesRequest) =>
    requests.put<Result>(`/identity/preferences`, updatePreferencesRequest),
  changePassword: (changePasswordRequest: ChangePasswordRequest) =>
    requests.put<Result>(`/identity/change-password`, changePasswordRequest),
  forgotPassword: (forgotPasswordRequest: ForgotPasswordRequest) =>
    requests.post<Result>(`/identity/forgot-password`, forgotPasswordRequest),
  resetPassword: (resetPasswordRequest: ResetPasswordRequest) =>
    requests.post<Result>(`/identity/reset-password`, resetPasswordRequest)
};

const Venues = {
  search: (params: SearchParams) =>
    requests.post<PaginatedResult<Venue>>(`/venues/VenueListPaginated`, params),
  create: (venue: AddVenueRequest) =>
    requests.post<Result<Venue>>('/venues', venue),
  details: (id: string) => requests.get<Result<Venue>>(`/venues/${id}`),
  update: (venue: Venue) =>
    requests.put<Result<Venue>>(`/venues/${venue.id}`, venue),
  delete: (id: string) => requests.del<Result<string>>(`/venues/${id}`)
};

const Cars = {
  search: (params: SearchParamsCars) =>
    requests.post<PaginatedResult<Car>>(`/cars/CarListPaginated`, params),
  create: (car: AddCarRequest) => requests.post<Result<Car>>('/cars', car),
  details: (id: string) => requests.get<Result<Car>>(`/cars/${id}`),
  update: (car: Car) => requests.put<Result<Car>>(`/cars/${car.id}`, car),
  delete: (id: string) => requests.del<Result<string>>(`/cars/${id}`),
  massAdd: (file: FormData) =>
    requests.multipart<Result<Result<string>>>(`/cars/upload`, file),
  findCar: (request: FindCarRequest) =>
    requests.post<Result<Car>>(`/cars/findCar`, request)
};

const CarBrands = {
  search: (params: SearchParams) =>
    requests.post<PaginatedResult<CarBrand>>(
      `/cars/brands/CarsBrandListPaginated`,
      params
    ),
  create: (carBrand: AddCarBrandRequest) =>
    requests.post<Result<CarBrand>>('/cars/brands', carBrand),
  details: (id: string) => requests.get<Result<CarBrand>>(`/cars/brands/${id}`),
  update: (carBrand: CarBrand) =>
    requests.put<Result<CarBrand>>(`/cars/brands/${carBrand.id}`, carBrand),
  delete: (id: string) => requests.del<Result<string>>(`/cars/brands/${id}`)
};

const Engines = {
  search: (params: SearchParams) =>
    requests.post<PaginatedResult<Engine>>(
      `/cars/engines/EngineListPaginated`,
      params
    ),
  create: (engine: AddEngineRequest) =>
    requests.post<Result<Engine>>('/cars/engines', engine),
  details: (id: string) => requests.get<Result<Engine>>(`/cars/engines/${id}`),
  update: (engine: Engine) =>
    requests.put<Result<Engine>>(`/cars/engines/${engine.id}`, engine),
  delete: (id: string) => requests.del<Result<string>>(`/cars/engines/${id}`)
};

const CarModels = {
  search: (params: SearchParams) =>
    requests.post<PaginatedResult<CarModel>>(
      `/cars/models/CarsModelListPaginated`,
      params
    ),
  create: (carModel: AddCarModelRequest) =>
    requests.post<Result<CarModel>>('/cars/models', carModel),
  details: (id: string) => requests.get<Result<CarModel>>(`/car/models/${id}`),
  update: (carModel: CarModel) =>
    requests.put<Result<CarModel>>(`/cars/models/${carModel.id}`, carModel),
  delete: (id: string) => requests.del<Result<string>>(`/cars/models/${id}`)
};

const Rims = {
  search: (params: SearchParamsRims) =>
    requests.post<PaginatedResult<Rim>>(`/rims/rimListPaginated`, params),
  getByCar: (carId: RimByCarRequest) =>
    requests.post<Result<RimWithRelated[]>>(`/rims/RimsByCar`, carId),
  create: (rim: AddRimRequest) => {
    let formData = new FormData(); // Form data so that we can include the image file

    Object.entries(rim).forEach(([key, val]) => {
      formData.append(key, val);
    });

    return requests.post<Result<string>>('/rims/', formData, {
      headers: { 'Content-type': 'multipart/form-data' }
    });
  },
  upload: (file: FormData) =>
    requests.multipart<Result<string>>(`/rims/uploadFile`, file),
  uploadImages: (file: FormData) =>
    requests.multipart<string>(`/rims/uploadImages`, file),
  uploadModel: (file: FormData, destination: string) =>
    requests.multipart<string>(`/rims/uploadModel`, {
      file: file,
      destination: destination
    }),
  details: (id: string) => requests.get<Result<Rim>>(`/rims/${id}`),
  update: (rim: UpdateRimRequest) =>
    requests.put<Result<Rim>>(`/rims/${rim.Id}`, rim),
  delete: (id: string) => requests.del<Result<string>>(`/rims/${id}`),
  get: (id: string) => requests.get<Result<RimWithRelated>>(`/rims/${id}`),
  searchByName: (params: SearchParamsRims) =>
    requests.post<PaginatedResult<Rim>>(`/rims/search`, params),
  csv: () => requests.get<CSV[]>(`/rims/csv`),
  massAdd: (file: FormData) => requests.multipart(`/rims/massUpload`, file),
  massDelete: (file: FormData) =>
    requests.multipart<Result<string>>(`/rims/delete`, file),
  compatible: (request: CompatibilityRequest) =>
    requests.post<Result<boolean>>(`/rims/compatibility`, request)
};

const RimBrands = {
  get: () => requests.get<Result<TenantBrand[]>>(`/rims/brands/tenants`),
  search: (params: SearchParams) =>
    requests.post<PaginatedResult<RimBrand>>(
      `/rims/brands/RimsBrandListPaginated`,
      params
    ),
  create: (rimBrand: AddRimBrandRequest) =>
    requests.post<Result<RimBrand>>('/rims/brands', rimBrand),
  details: (id: string) => requests.get<Result<RimBrand>>(`/rims/brands/${id}`),
  update: (rimBrand: RimBrand) =>
    requests.put<Result<RimBrand>>(`/rims/brands/${rimBrand.id}`, rimBrand),
  delete: (id: string) => requests.del<Result<string>>(`/rims/brands/${id}`)
};

const RimColors = {
  search: (params: SearchParams) =>
    requests.post<PaginatedResult<RimColor>>(
      `/rims/colors/RimsColorListPaginated`,
      params
    ),
  create: (rimColor: AddRimColorRequest) =>
    requests.post<Result<RimColor>>('/rims/colors', rimColor),
  details: (id: string) => requests.get<Result<RimColor>>(`/rims/colors/${id}`),
  update: (rimColor: RimColor) =>
    requests.put<Result<RimColor>>(`/rims/colors/${rimColor.id}`, rimColor),
  delete: (id: string) => requests.del<Result<string>>(`/rims/colors/${id}`)
};

const RimShells = {
  search: (params: SearchParams) =>
    requests.post<PaginatedResult<RimShell>>(
      `/rims/shells/RimsShellListPaginated`,
      params
    ),
  create: (rimShell: AddRimShellRequest) =>
    requests.post<Result<RimShell>>('/rims/shells', rimShell),
  details: (id: string) => requests.get<Result<RimShell>>(`/rims/shells/${id}`),
  update: (rimShell: RimShell) =>
    requests.put<Result<RimShell>>(`/rims/shells/${rimShell.id}`, rimShell),
  delete: (id: string) => requests.del<Result<string>>(`/rims/shells/${id}`)
};

const RimSpacings = {
  list: () => requests.get<Result<RimSpacing[]>>(`/rims/spacings/`),
  search: (params: SearchParams) =>
    requests.post<PaginatedResult<RimSpacing>>(`/rims/spacings/`, params),
  create: (rimSpacing: AddRimSpacingRequest) =>
    requests.post<Result<RimSpacing>>('/rims/spacings', rimSpacing),
  details: (id: string) =>
    requests.get<Result<RimSpacing>>(`/rims/spacings/${id}`),
  update: (rimSpacing: RimSpacing) =>
    requests.put<Result<RimShell>>(
      `/rims/spacings/${rimSpacing.id}`,
      rimSpacing
    ),
  delete: (id: string) => requests.del<Result<string>>(`/rims/spacings/${id}`)
};

const Users = {
  list: () => requests.get<Result<User[]>>('/identity/'),
  create: (appUser: RegisterUserRequest) =>
    requests.post<Result<User>>(`/identity/register`, appUser),
  details: (id: string) => requests.get<Result<User>>(`/identity/user/${id}`),
  update: (user: User) =>
    requests.put<Result<User>>(`/identity/user/${user.id}`, user),
  delete: (id: string) => requests.del<Result<string>>(`/identity/user/${id}`)
};

const Tenants = {
  list: () => requests.get<Result<Tenant[]>>('/tenants'),
  details: (id: string) => requests.get<Result<Tenant>>(`/tenants/${id}`),
  create: (tenant: CreateTenantRequest) =>
    requests.post<Result<Tenant>>(`/tenants`, tenant),
  update: (tenant: Tenant) => requests.put<Result<Tenant>>(`/tenants/`, tenant),
  updateBasic: (tenant: UpdateTenantBasic) =>
    requests.put<Result<UpdateTenantBasic>>(`/tenants/basic`, tenant),
  brands: (id: string) =>
    requests.get<Result<TenantBrand[]>>(`/tenants/brands/${id}`),
  delete: (id: string) => requests.del<Result<string>>(`/tenants/${id}`)
};

const CurrentTenants = {
  list: () => requests.get<Result<CurrentTenant>>('/tenants/current'),
  update: (currentTenant: CurrentTenant) =>
    requests.put<Result<CurrentTenant>>(`/tenants/current`, currentTenant),
  updateBrands: (brands: TenantRimBrand) =>
    requests.put<Result<TenantBrand>>(`/tenants/brands`, brands),
  upload: (file: FormData) =>
    requests.multipart<Result<string>>(`/tenants/uploadFile`, file),
  logo: () => requests.get<Result<string>>(`/tenants/logo`)
};

const TenantRimBrands = {
  update: (tenantRimBrand: TenantRimBrand) =>
    requests.put<Result<TenantRimBrand>>(`/tenants/brands`, tenantRimBrand)
};

const Contacts = {
  list: () => requests.get<Result<Contact>>('/tenants/contacts'),
  update: (contact: Contact) =>
    requests.put<Result<Contact>>(`/tenants/contacts`, contact)
};

const PrivacyPolicies = {
  list: () => requests.get<Result<PrivacyPolicy>>('/tenants/privacy-policies'),
  update: (privacyPolicie: PrivacyPolicy) =>
    requests.put<Result<PrivacyPolicy>>(
      `/tenants/privacy-policies`,
      privacyPolicie
    )
};

const agent = {
  Account,
  Users,
  Tenants,
  CurrentTenants,
  Venues,
  Cars,
  Engines,
  CarModels,
  CarBrands,
  Rims,
  RimShells,
  RimColors,
  RimSpacings,
  TenantRimBrands,
  RimBrands,
  PrivacyPolicies,
  Contacts
};

export default agent;
