import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { Car } from '../models/car';
import { SearchParamsCars } from '../models/searchParamsCars';
import { PaginatedResult } from '../models/responseWrappers';
import { toast } from 'material-react-toastify';

export default class CarStore {
  cars: Car[] = [];
  carMetaData: Omit<PaginatedResult<Car>, 'data'> | null = null;

  loading = false;
  loadingInitial = false;

  searchedCar: string = '';
  displayCar: boolean = false;

  queryString: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  setQueryString = (value: string) => {
    runInAction(() => {
      this.queryString = value;
    });
  };

  setDisplayCar = (value: boolean) => {
    runInAction(() => {
      this.displayCar = value;
    });
  };

  // loading setter (initial page load)
  setLoadingInitial = (state: boolean) => {
    runInAction(() => {
      this.loadingInitial = state;
    });
  };

  setSearchedCar = (carId: string) => {
    runInAction(() => {
      this.searchedCar = carId;
      localStorage.setItem('searchedCar', carId);
    });
  };

  // loading setter
  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };
  // set pagination meta data
  setCarMetaData = (metaData: Omit<PaginatedResult<Car>, 'data'>) => {
    runInAction(() => {
      this.carMetaData = metaData;
    });
  };

  // load venues - full list of venues from api
  loadCars = async (
    pageNumber: number = 1,
    pageSize: number = 5,
    keyword: string = '',
    brand: string = '',
    model: string = '',
    engine: string = ''
  ) => {
    this.setLoadingInitial(true);
    try {
      const params: SearchParamsCars = {
        pageNumber,
        pageSize,
        keyword,
        brand,
        model,
        engine
      };

      const { data, ...metaData } = await agent.Cars.search(params);
      runInAction(() => {
        this.cars = data;
      });
      this.setCarMetaData(metaData);
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };

  createCar = async (car: Car): Promise<boolean | undefined> => {
    this.setLoading(true);
    try {
      const carRequestBody = {
        Name: car.name,
        ModelId: car.modelId,
        ModelName: car.modelName,
        BrandId: car.brandId,
        BrandName: car.brandName,
        CentreBore: car.centreBore.toString().replace(',', '.'),
        MinRimSize: car.minRimSize.toString(),
        MaxRimSize: car.maxRimSize.toString(),
        MinEt: car.minEt.toString().replace(',', '.'),
        MaxEt: car.maxEt.toString().replace(',', '.'),
        EngineId: car.engineId,
        EngineName: car.engineName,
        FasteningType: car.fasteningType,
        Size: car.size.toString(),
        Screw: car.screw.toString().replace(',', '.'),
        Spacing: car.spacing.toString()
      };

      const response = await agent.Cars.create(carRequestBody);

      this.setLoading(false);
      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }
      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };

  getCar = async (id: string): Promise<Car | null> => {
    try {
      if (id === undefined || id.length <= 0) return null;

      const response = await agent.Cars.details(id);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  // update venue
  updateCar = async (car: Car): Promise<boolean | undefined> => {
    this.setLoading(true);

    try {
      const response = await agent.Cars.update(car);
      this.setLoading(false);
      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }
      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };

  // delete venue
  deleteCar = async (id: string) => {
    this.setLoadingInitial(true);

    try {
      await agent.Cars.delete(id);
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };

  massAddCar = async (file: FormData): Promise<boolean> => {
    this.setLoadingInitial(true);

    try {
      const response = await agent.Cars.massAdd(file);

      this.setLoadingInitial(false);
      return response.succeeded;
    } catch (error) {
      this.setLoadingInitial(false);
      return false;
    }
  };
}
