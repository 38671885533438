import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { MIN_SCALE, MAX_SCALE } from './arConst';
import imageHelper from './imageHelper';

/**
 * Returns a semi-transparent material for use in a THREE.js mesh.
 *
 * @returns {THREE.MeshStandardMaterial} - The transparent material.
 */
export const getTransparentMaterial = (): THREE.MeshStandardMaterial => {
  return new THREE.MeshStandardMaterial({
    color: '0xccffff',
    transparent: true,
    opacity: 0.3
  });
};

/**
 * Loads a GLTF file asynchronously from the specified URL and returns a cloned mesh of the loaded scene.
 * Optionally, if the `isGhost` parameter is set to `true`, the loaded mesh will be updated with a transparent material.
 *
 * @param {string} url - The URL of the GLTF file to load.
 * @param {boolean} isGhost - A boolean flag indicating whether the loaded mesh should be updated with a transparent material.
 * @returns {Promise<THREE.Group>} - A Promise that resolves to a cloned mesh of the loaded scene with updated materials if `isGhost` is `true`.
 */
export const loadGlb = async (
  url: string,
  isGhost: boolean
): Promise<THREE.Group> => {
  const loader = new GLTFLoader();
  const gltfData = await loader.loadAsync(url);
  const mesh = gltfData.scene.clone();
  if (isGhost) {
    const newMaterial = getTransparentMaterial();

    mesh.traverse((o) => {
      if ((o as THREE.Mesh).isMesh) (o as THREE.Mesh).material = newMaterial;
    });
  }

  return mesh;
};

/**
 * Asynchronously loads texture and alpha map and creates a MeshBasicMaterial for a plane.
 *
 * @param {string} mapUrl - URL of the texture map.
 * @param {string} alphaUrl - URL of the alpha map.
 * @param {boolean} isGhost - Flag indicating whether the plane should appear as a ghost (partially transparent).
 * @returns {Promise<THREE.MeshBasicMaterial>} A promise that resolves to a MeshBasicMaterial for the plane.
 */
const getPlaneMaterial = async (
  mapUrl: string,
  alphaUrl: string,
  isGhost: boolean
): Promise<THREE.MeshBasicMaterial> => {
  const loader = new THREE.TextureLoader();

  const map = loader.load(mapUrl);
  const alphaMap = loader.load(alphaUrl);

  const material = isGhost
    ? new THREE.MeshBasicMaterial({
        transparent: true,
        alphaMap: alphaMap,
        opacity: 0.3
      })
    : new THREE.MeshBasicMaterial({
        transparent: true,
        map: map,
        alphaMap: alphaMap
      });

  return material;
};

/**
 * Loads a 2D model and returns a group containing the loaded model.
 *
 * @param {string} url - The URL of the 2D texture.
 * @param {boolean} isGhost - Indicates whether the model should be rendered as a ghost.
 * @returns {Promise<THREE.Group>} - A promise that resolves to a THREE.Group containing the loaded model.
 */
export const load2Dmodel = async (
  url: string,
  model: string,
  isGhost: boolean
): Promise<THREE.Group> => {
  const group = new THREE.Group();
  console.log(imageHelper.getAlphaImageUrl(url));
  const material = await getPlaneMaterial(
    imageHelper.getImageUrl(url),
    imageHelper.getAlphaImageUrl(url),
    isGhost
  );
  const geometry = new THREE.PlaneGeometry(1, 1);
  const plane = new THREE.Mesh(geometry, material);
  geometry.computeVertexNormals();
  group.add(plane);

  return group;
};

/**
 * Changes the scale of a THREE.js object uniformly.
 *
 * @param {THREE.Object3D} object - The object whose scale needs to be changed.
 * @param {number} scale - The scale value to be applied.
 */
export const changeScale = (object: THREE.Object3D, scale: number) => {
  const newScale = clamp(scale, MIN_SCALE, MAX_SCALE);

  object.scale.x = newScale;
  object.scale.y = newScale;
  object.scale.z = newScale;
};

/**
 * Clamps a number between a minimum and maximum value.
 *
 * @param {number} num - The number to be clamped.
 * @param {number} min - The minimum value.
 * @param {number} max - The maximum value.
 * @returns {number} - The clamped number.
 */
export const clamp = (num: number, min: number, max: number): number =>
  Math.min(Math.max(num, min), max);
