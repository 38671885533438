import { createContext, useContext } from 'react';
import AppUserStore from './appUserStore';
import CommonStore from './commonStore';
import TenantStore from './tenantStore';
import CurrentUserStore from './currentUserStore';
import VenueStore from './venueStore';
import CarStore from './carStore';
import CarBrandStore from './carBrandStore';
import EngineStore from './engineStore';
import CarModelStore from './carModelStore';
import RimStore from './rimStore';
import RimBrandStore from './rimBrandStore';
import RimShellStore from './rimShellStore';
import RimColorStore from './rimColorStore';
import RimSpacingStore from './rimSpacingStore';
import CurrentTenantStore from './currentTenantStore';
import ContactStore from './contactStore';
import PrivacyPolicyStore from './privacyPolicyStore';
import RimDetailsStore from './rimDetailsStore';
import ColorStore from './colorStore';
import ARStore from './AR/arStore';

interface Store {
  commonStore: CommonStore;
  currentUserStore: CurrentUserStore;
  appUserStore: AppUserStore;
  tenantStore: TenantStore;
  carStore: CarStore;
  carBrandStore: CarBrandStore;
  engineStore: EngineStore;
  venueStore: VenueStore;
  carModelStore: CarModelStore;
  rimBrandStore: RimBrandStore;
  rimStore: RimStore;
  rimSpacingStore: RimSpacingStore;
  rimColorStore: RimColorStore;
  rimShellStore: RimShellStore;
  contactStore: ContactStore;
  currentTenantStore: CurrentTenantStore;
  privacyPolicyStore: PrivacyPolicyStore;
  rimDetailsStore: RimDetailsStore;
  colorStore: ColorStore;
  arStore: ARStore;
}

export const store: Store = {
  commonStore: new CommonStore(),
  currentUserStore: new CurrentUserStore(),
  appUserStore: new AppUserStore(),
  tenantStore: new TenantStore(),
  carStore: new CarStore(),
  carBrandStore: new CarBrandStore(),
  engineStore: new EngineStore(),
  venueStore: new VenueStore(),
  rimStore: new RimStore(),
  carModelStore: new CarModelStore(),
  rimBrandStore: new RimBrandStore(),
  rimShellStore: new RimShellStore(),
  rimSpacingStore: new RimSpacingStore(),
  rimColorStore: new RimColorStore(),
  contactStore: new ContactStore(),
  currentTenantStore: new CurrentTenantStore(),
  privacyPolicyStore: new PrivacyPolicyStore(),
  rimDetailsStore: new RimDetailsStore(),
  colorStore: new ColorStore(),
  arStore: new ARStore()
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
