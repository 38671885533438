import AccountSettingsIcon from './AccountSettings';
import PagesIcon from './Pages';
import UserManagementIcon from './UserManagement';
import UserProfileIcon from './UserProfile';

export default {
  PagesIcon,
  UserProfileIcon,
  UserManagementIcon,
  AccountSettingsIcon
};
