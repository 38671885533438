import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { Engine } from '../models/engine';
import { SearchParams } from '../models/searchParams';
import { PaginatedResult } from '../models/responseWrappers';
import { toast } from 'material-react-toastify';

export default class EngineStore {
  engines: Engine[] = [];
  engineMetaData: Omit<PaginatedResult<Engine>, 'data'> | null = null;

  loading = false;
  loadingInitial = false;

  queryString: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  setQueryString = (value: string) => {
    runInAction(() => {
      this.queryString = value;
    });
  };

  // loading setter (initial page load)
  setLoadingInitial = (state: boolean) => {
    runInAction(() => {
      this.loadingInitial = state;
    });
  };
  // loading setter
  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };
  // set pagination meta data
  setEngineMetaData = (metaData: Omit<PaginatedResult<Engine>, 'data'>) => {
    runInAction(() => {
      this.engineMetaData = metaData;
    });
  };

  loadEngines = async (
    pageNumber: number = 1,
    pageSize: number = 100000,
    keyword: string = '',
    orderByPriority: boolean = false,
    brand: string = ''
  ) => {
    this.setLoadingInitial(true);
    try {
      const params: SearchParams = {
        orderByPriority,
        pageNumber,
        pageSize,
        keyword,
        brand
      };
      const { data, ...metaData } = await agent.Engines.search(params);
      runInAction(() => {
        this.engines = data;
      });
      this.setEngineMetaData(metaData);
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };

  // create venue
  createEngine = async (engine: Engine): Promise<boolean | undefined> => {
    this.setLoading(true);

    try {
      const engineRequestBody = {
        Name: engine.name
      };
      const response = await agent.Engines.create(engineRequestBody);
      this.setLoading(false);
      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }
      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };

  updateEngine = async (engine: Engine): Promise<boolean | undefined> => {
    this.setLoading(true);

    try {
      const response = await agent.Engines.update(engine);
      this.setLoading(false);
      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }
      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };

  // delete venue
  deleteEngine = async (id: string) => {
    this.setLoadingInitial(true);

    try {
      await agent.Engines.delete(id);
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };
}
