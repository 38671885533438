import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'material-react-toastify';
import agent from '../api/agent';
import { Contact } from 'app/models/contact';

export default class ContactStore {
  contacts: Contact | null = null;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  // loading setter (initial page load)
  setLoadingInitial = (state: boolean) => {
    runInAction(() => {
      this.loadingInitial = state;
    });
  };

  // loading setter
  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };

  // load tenants
  loadContact = async () => {
    this.setLoadingInitial(true);
    try {
      const result = await agent.Contacts.list();
      runInAction(() => {
        this.contacts = result.data;
      });
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };

  updateContact = async (contact: Contact): Promise<boolean | undefined> => {
    this.setLoading(true);

    try {
      const response = await agent.Contacts.update(contact);
      this.setLoading(false);
      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }
      runInAction(() => {
        this.contacts = contact;
      });
      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };
}
