export function removeDuplicates<T>(arr: T[]): T[] {
  return Array.from(new Set(arr));
}

export function componentWiseAdd(a: number[], b: number[]): number[] {
  if (!a || !b) throw new Error("Array can't be null or undefined.");
  if (a.length === 0 || b.length !== a.length)
    throw new Error('Arrays length mismatch.');

  const sum = a.map((e, i) => e + b[i]);

  return sum;
}

export function arrayAnd(arr: boolean[]): boolean {
  return arr.reduce((acc, current) => acc && current, true);
}
