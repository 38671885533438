import agent from '../api/agent';
import { makeAutoObservable, runInAction } from 'mobx';
import { RimCompact, RimWithRelated } from 'app/models/rimCompact';

export default class RimDetailsStore {
  currentlyChosenRim: RimWithRelated | null = null;
  rimsRelatedColor: RimCompact[] = [];
  rimsRelatedSizes: RimCompact[] = [];
  currentSize: number = 0;
  currentColor: string = '';
  imageUrl: string = '';

  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(state: boolean) {
    runInAction(() => {
      this.isLoading = state;
    });
  }

  getRim = async (rimId: string) => {
    this.setLoading(true);
    try {
      const rim = (await agent.Rims.get(rimId)).data;

      runInAction(() => {
        this.currentlyChosenRim = rim;
        this.currentSize = rim.size;
        this.currentColor = rim.colorId;
        this.rimsRelatedColor = rim.relatedColors;
        this.rimsRelatedSizes = rim.relatedSizes.sort(
          (a, b) => a.size - b.size
        );
        this.imageUrl = rim.imageFilePath;
      });

      this.setLoading(false);
    } catch (error) {
      this.setLoading(false);
    }
  };
}
