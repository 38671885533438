import { Roles } from 'app/models/roles';
import Icons from '../../../icons/sidebar';
import {
  ColorLens,
  FiberSmartRecord,
  Gradient,
  Sell
} from '@mui/icons-material';

const navListRim = [
  {
    title: 'navlist.rim_list',
    Icon: FiberSmartRecord,
    path: '/admin/rims',
    roles: [Roles.root, Roles.admin, Roles.editor, Roles.basic]
  },
  {
    title: 'navlist.rim_brands',
    Icon: Sell,
    path: '/admin/rims/brands',
    roles: [Roles.root, Roles.admin, Roles.editor, Roles.basic]
  },
  {
    title: 'navlist.rim_color',
    Icon: ColorLens,
    path: '/admin/rims/colors',
    roles: [Roles.root, Roles.admin, Roles.editor, Roles.basic]
  },
  {
    title: 'navlist.rim_shell',
    Icon: Gradient,
    path: '/admin/rims/shells',
    roles: [Roles.root, Roles.admin, Roles.editor, Roles.basic]
  }
];

export default navListRim;
