import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'material-react-toastify';
import agent from '../api/agent';
import { PrivacyPolicy } from 'app/models/privacyPolicy';

export default class PrivacyPolicyStore {
  privacyPolicies: PrivacyPolicy | null = null;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  // loading setter (initial page load)
  setLoadingInitial = (state: boolean) => {
    runInAction(() => {
      this.loadingInitial = state;
    });
  };

  // loading setter
  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };

  // load tenants
  loadPrivacyPolicy = async () => {
    this.setLoadingInitial(true);
    try {
      const result = await agent.PrivacyPolicies.list();
      runInAction(() => {
        this.privacyPolicies = result.data;
      });
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };

  updatePrivacyPolicy = async (
    privacyPolicy: PrivacyPolicy
  ): Promise<boolean | undefined> => {
    this.setLoading(true);

    try {
      const response = await agent.PrivacyPolicies.update(privacyPolicy);
      this.setLoading(false);
      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }
      runInAction(() => {
        this.privacyPolicies = privacyPolicy;
      });
      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };
}
