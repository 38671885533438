import { shutdownXR } from 'app/utils/arUtils';
import { makeAutoObservable, runInAction } from 'mobx';

interface IAppState {
  sessionActive: boolean;
  session: XRSession | null;
  gl: THREE.WebGLRenderer | null;
  isLoading: boolean;
  rimId: string;
}

const initialState: IAppState = {
  sessionActive: false,
  session: null,
  gl: null,
  isLoading: false,
  rimId: ''
};

class AppStore {
  appState: IAppState = initialState;

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    runInAction(() => {
      this.appState = initialState;
    });
  }

  setRimId(id: string) {
    runInAction(() => {
      this.appState.rimId = id;
    });
  }

  setSession(session: XRSession) {
    runInAction(() => {
      this.appState.session = session;
    });
  }

  setSessionActive(active: boolean) {
    runInAction(() => {
      this.appState.sessionActive = true;
    });
  }

  setIsLoading(isLoading: boolean) {
    runInAction(() => {
      this.appState.isLoading = isLoading;
    });
  }

  setRenderer = (renderer: THREE.WebGLRenderer) => {
    runInAction(() => {
      this.appState.gl = renderer;
    });
  };

  setupSession = async (session: XRSession, id: string) => {
    runInAction(() => {
      this.setRimId(id);
      this.setSession(session);
    });
  };

  exitSession = async () => {
    await shutdownXR(this.appState.session);
  };
}

const appStore = new AppStore();
export default appStore;
