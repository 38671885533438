import * as React from 'react';
import { useEffect } from 'react';
import { StyledEngineProvider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ToastContainer } from 'material-react-toastify';
import { useStore } from './app/stores/store';
import LoadingScreen from './components/LoadingScreen';
import { Outlet } from 'react-router-dom';
import i18next from 'i18next';
import 'internationalization';

function App() {
  const {
    commonStore,
    currentUserStore,
    currentTenantStore,
    contactStore,
    privacyPolicyStore,
    colorStore
  } = useStore();
  const isDevelopment: boolean =
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

  useEffect(() => {
    privacyPolicyStore.loadPrivacyPolicy();
    if (!currentTenantStore.currentTenants) {
      currentTenantStore.loadCurrentTenants().then(() => {
        colorStore.setTheme(
          currentTenantStore.currentTenants?.color1!,
          currentTenantStore.currentTenants?.color2!,
          currentTenantStore.currentTenants?.color3!
        );

        if (currentTenantStore.currentTenants?.defaultLocale) {
          const lang = navigator.language.split('-')[0];
          i18next.changeLanguage(lang);
          commonStore.setLanguage(lang);
        }
        contactStore.loadContact().then(() => {
          if (commonStore.token) {
            if (currentUserStore.currentUser) return;
            currentUserStore
              .getCurrentUser()
              .finally(() => commonStore.setAppLoaded());
          } else {
            commonStore.setAppLoaded();
          }
        });
      });
    } else {
      if (commonStore.token) {
        if (currentUserStore.currentUser) return;
        currentUserStore
          .getCurrentUser()
          .finally(() => commonStore.setAppLoaded());
      } else {
        commonStore.setAppLoaded();
      }
    }
  }, [commonStore, currentUserStore, currentTenantStore, contactStore]);

  useEffect(() => {
    const host = window.location.host;
    const subdomain = host.split('.');
    const minimumSegments = isDevelopment ? 2 : 3;

    if (subdomain.length == minimumSegments) {
      commonStore.setSubdomain();
    }
  }, []);

  if (!commonStore.appLoaded) return <LoadingScreen />;

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ToastContainer
          position="bottom-left"
          hideProgressBar
          draggable
          autoClose={3000}
        />
        <Outlet />
      </StyledEngineProvider>
    </>
  );
}

export default observer(App);
