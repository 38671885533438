import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { Rim, RimFormData } from '../models/rim';
import { SearchParamsRims } from '../models/searchParamsRims';
import { PaginatedResult, Result } from '../models/responseWrappers';
import { toast } from 'material-react-toastify';

export default class RimStore {
  rims: Rim[] = [];
  rimMetaData: Omit<PaginatedResult<Rim>, 'data'> | null = null;

  loading = false;
  loadingInitial = false;
  searchPhrase = '';
  panelId = '';

  constructor() {
    makeAutoObservable(this);
  }

  setLoadingInitial = (state: boolean) => {
    runInAction(() => {
      this.loadingInitial = state;
    });
  };

  setSearchPhrase = (phrase: string) => {
    runInAction(() => {
      this.searchPhrase = phrase;
    });
  };

  setPanelId = (panelId: string) => {
    runInAction(() => {
      this.panelId = panelId;
    });
  };

  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };
  setRimMetaData = (metaData: Omit<PaginatedResult<Rim>, 'data'>) => {
    runInAction(() => {
      this.rimMetaData = metaData;
    });
  };

  loadRimsByName = async (search: string, pageNumber: number) => {
    this.setLoadingInitial(true);
    try {
      const params: SearchParamsRims = {
        pageNumber: pageNumber,
        orderByPriority: true,
        pageSize: 10,
        keyword: search,
        brand: '',
        shell: '',
        size: 0,
        color: '',
        et: 0,
        spacing: '',
        carId: ''
      };

      const { data, ...metaData } = await agent.Rims.searchByName(params);
      runInAction(() => {
        this.rims = data;
      });
      this.setRimMetaData(metaData);
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };

  loadRims = async (
    panelId: string = '',
    kzs: string = '',
    ean: string = '',
    pageNumber: number = 1,
    pageSize: number = 5,
    keyword: string = '',
    orderByPriority: boolean = true,
    brand: string = '',
    shell: string = '',
    size: number = 0,
    color: string = '',
    et: number = 0,
    spacing: string = '',
    carId: string = ''
  ) => {
    this.setLoadingInitial(true);
    try {
      const params: SearchParamsRims = {
        panelId,
        kzs,
        ean,
        pageNumber,
        pageSize,
        keyword,
        brand,
        color,
        shell,
        size,
        spacing,
        et,
        carId,
        orderByPriority
      };

      const { data, ...metaData } = await agent.Rims.search(params);
      runInAction(() => {
        this.rims = data;
      });
      this.setRimMetaData(metaData);
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };

  getRim = async (rimId: string): Promise<Result<Rim> | null> => {
    this.setLoading(true);
    try {
      const rim = await agent.Rims.get(rimId);
      this.setLoading(false);
      return rim;
    } catch (error) {
      this.setLoading(false);
      return null;
    }
  };

  createRim = async (rim: RimFormData): Promise<boolean | undefined> => {
    this.setLoading(true);
    try {
      const rimRequestBody = {
        PanelId: rim.panelId,
        BrandId: rim.brandId,
        BrandName: rim.brandName,
        Model: rim.model,
        Ean: rim.ean,
        Kzs: rim.kzs,
        CentreBore: rim.centreBore,
        Size: rim.size,
        ColorId: rim.colorId,
        ColorName: rim.colorName,
        shellId: rim.shellId,
        shellName: rim.shellName,
        Spacings: rim.spacings,
        Width: rim.width,
        Et: rim.et,
        Priority: rim.priority,
        isAvailable: rim.isAvailable == '1',
        imageFilePath: '',
        arModelFilePath: '',
        detailsModelFilePath: '',
        files: rim.files
      };

      await agent.Rims.create(rimRequestBody);
      this.setLoading(false);
      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };

  deleteRim = async (id: string) => {
    this.setLoadingInitial(true);

    try {
      await agent.Rims.delete(id);
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };

  massAddRim = async (file: FormData) => {
    this.setLoadingInitial(true);

    try {
      const response = await agent.Rims.massAdd(file);
      console.table(response);

      return true;
    } catch (error) {
      console.log(error);

      return false;
    }
  };

  massDeleteRim = async (file: FormData): Promise<boolean> => {
    try {
      const response = await agent.Rims.massDelete(file);

      return response.succeeded;
    } catch (error) {
      return false;
    }
  };
}
