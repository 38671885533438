import { toast } from 'material-react-toastify';
import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { RegisterUserRequest, User } from '../models/user';
import { ChangePasswordRequest } from 'app/models/currentUser';

export default class AppUserStore {
  users: User[] = [];

  loading = false; // this loading is for adding/editing button
  loadingDelete = false; // this loading is for delete button
  loadingInitial = false; // this loading is for the view

  leavingShowcase = false;

  // Let Mobx auto create the interface for this class
  constructor() {
    makeAutoObservable(this);
  }

  // Computed property - returns an array of users sorted by created on date, newest first
  get appUsersSorted() {
    return Array.from(this.users.values()).sort(
      (a, b) =>
        new Date(b.createdOn).valueOf() - new Date(a.createdOn).valueOf()
    );
  }

  // Loading setter (initial page load)
  setLoadingInitial = (state: boolean) => {
    runInAction(() => {
      this.loadingInitial = state;
    });
  };

  setLeavingShowcase = (state: boolean) => {
    runInAction(() => {
      this.leavingShowcase = state;
    });
  };

  // Loading setter (delete user button)
  setLoadingDelete = (state: boolean) => {
    runInAction(() => {
      this.loadingDelete = state;
    });
  };

  // Loading setter
  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };

  // Load app users - triggered by visiting user list
  loadAppUsers = async () => {
    this.setLoadingInitial(true);
    try {
      const result = await agent.Users.list();

      runInAction(() => {
        this.users = result.data;
      });
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };

  // Register a new user
  createAppUser = async (
    appUser: RegisterUserRequest
  ): Promise<boolean | undefined> => {
    this.setLoading(true);

    try {
      const response = await agent.Users.create(appUser);
      this.setLoading(false);
      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }

      runInAction(() => {
        const newAppUser = response.data; // the user returned from the api
        this.users.push(newAppUser); // add to registry list (local memory) - prevents having to reload the table
      });
      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };

  // Update an existing user
  updateAppUser = async (user: User): Promise<boolean | undefined> => {
    this.setLoading(true);

    try {
      const response = await agent.Users.update(user);
      this.setLoading(false);
      if (!response.succeeded) {
        // handle any errors returned from api
        toast.error(response.messages[0]);
        return false;
      }
      runInAction(() => {
        const userIndex = this.users.findIndex((x) => x.id == user.id); // find index of user and update
        this.users[userIndex] = user;
      });
      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };

  // Delete user
  deleteAppUser = async (id: string): Promise<boolean | undefined> => {
    this.setLoadingDelete(true);

    try {
      const response = await agent.Users.delete(id); // api call to delete from database
      this.setLoadingDelete(false);
      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }
      runInAction(() => {
        const userIndex = this.users.findIndex((x) => x.id == id); // find index of user and update
        this.users.splice(userIndex, 1);
      });
      return true;
    } catch (error) {
      this.setLoadingDelete(false);
      return false;
    }
  };

  changePassword = async (
    changePasswordRequest: ChangePasswordRequest
  ): Promise<boolean | undefined> => {
    this.setLoading(true);

    try {
      const response = await agent.Account.changePassword(
        changePasswordRequest
      );

      this.setLoading(false);

      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }

      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };
}
