import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { RimShell } from '../models/rimShell';
import { SearchParams } from '../models/searchParams';
import { PaginatedResult } from '../models/responseWrappers';
import { toast } from 'material-react-toastify';

export default class RimShellStore {
  rimShells: RimShell[] = [];
  rimShellMetaData: Omit<PaginatedResult<RimShell>, 'data'> | null = null;

  loading = false;
  loadingInitial = false;

  queryString: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  setQueryString = (value: string) => {
    runInAction(() => {
      this.queryString = value;
    });
  };

  setLoadingInitial = (state: boolean) => {
    runInAction(() => {
      this.loadingInitial = state;
    });
  };
  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };
  setRimShellMetaData = (metaData: Omit<PaginatedResult<RimShell>, 'data'>) => {
    runInAction(() => {
      this.rimShellMetaData = metaData;
    });
  };

  loadRimShells = async (
    pageNumber: number = 1,
    pageSize: number = 200,
    orderByPriority: string = '',
    keyword: string = '',
    brand: string = ''
  ) => {
    this.setLoadingInitial(true);
    try {
      const params: SearchParams = {
        orderByPriority,
        pageNumber,
        pageSize,
        keyword,
        brand
      };
      const { data, ...metaData } = await agent.RimShells.search(params);
      runInAction(() => {
        this.rimShells = data;
      });
      this.setRimShellMetaData(metaData);
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };

  createRimShell = async (rimShell: RimShell): Promise<boolean | undefined> => {
    this.setLoading(true);

    try {
      const rimShellRequestBody = {
        Name: rimShell.name
      };
      const response = await agent.RimShells.create(rimShellRequestBody);
      this.setLoading(false);
      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }
      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };

  updateRimShell = async (rimShell: RimShell): Promise<boolean | undefined> => {
    this.setLoading(true);

    try {
      const response = await agent.RimShells.update(rimShell);
      this.setLoading(false);
      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }
      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };

  deleteRimShell = async (id: string) => {
    this.setLoadingInitial(true);

    try {
      await agent.RimShells.delete(id);
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };
}
