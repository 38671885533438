import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Icon } from '@ailibs/feather-react-ts';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DataUpdate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);

  const handleClick = () => {
    navigate('/admin/personalization/update-data');
  };
  return (
    <ListItemButton onClick={handleClick}>
      <ListItemIcon>
        <Icon name="database" />
      </ListItemIcon>
      <ListItemText primary={t('navlist.update_data')} />
    </ListItemButton>
  );
};

export default DataUpdate;
