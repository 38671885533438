import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { CarBrand } from '../models/carBrand';
import { SearchParams } from '../models/searchParams';
import { PaginatedResult } from '../models/responseWrappers';
import { toast } from 'material-react-toastify';

export default class CarBrandStore {
  carBrands: CarBrand[] = [];
  carBrandMetaData: Omit<PaginatedResult<CarBrand>, 'data'> | null = null;

  loading = false;
  loadingInitial = false;

  queryString: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  setQueryString = (value: string) => {
    runInAction(() => {
      this.queryString = value;
    });
  };

  // loading setter (initial page load)
  setLoadingInitial = (state: boolean) => {
    runInAction(() => {
      this.loadingInitial = state;
    });
  };
  // loading setter
  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };
  // set pagination meta data
  setCarBrandMetaData = (metaData: Omit<PaginatedResult<CarBrand>, 'data'>) => {
    runInAction(() => {
      this.carBrandMetaData = metaData;
    });
  };

  loadCarBrands = async (
    pageNumber: number = 1,
    pageSize: number = 5000,
    keyword: string = '',
    orderByPriority: boolean = false,
    brand: string = ''
  ) => {
    this.setLoadingInitial(true);
    try {
      const params: SearchParams = {
        orderByPriority,
        pageNumber,
        pageSize,
        keyword,
        brand
      };
      const { data, ...metaData } = await agent.CarBrands.search(params);
      runInAction(() => {
        this.carBrands = data;
      });
      this.setCarBrandMetaData(metaData);
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };

  createCarBrand = async (carBrand: CarBrand): Promise<boolean | undefined> => {
    this.setLoading(true);

    try {
      const carBrandRequestBody = {
        Name: carBrand.name
      };
      const response = await agent.CarBrands.create(carBrandRequestBody);
      this.setLoading(false);
      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }
      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };

  updateCarBrand = async (carBrand: CarBrand): Promise<boolean | undefined> => {
    this.setLoading(true);

    try {
      const response = await agent.CarBrands.update(carBrand);
      this.setLoading(false);
      if (!response.succeeded) {
        toast.error(response.messages[0]);
        return false;
      }
      return true;
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };

  deleteCarBrand = async (id: string) => {
    this.setLoadingInitial(true);

    try {
      await agent.CarBrands.delete(id);
      this.setLoadingInitial(false);
    } catch (error) {
      this.setLoadingInitial(false);
    }
  };
}
