import { FC } from 'react';
import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStore } from 'app/stores/store';
import 'react-responsive-modal/styles.css';

const NavMenu: FC = () => {
  const { t } = useTranslation(['client']);
  const navigate = useNavigate();

  const { colorStore } = useStore();

  return (
    <Grid
      display="flex"
      direction="column"
      alignItems="center"
      justifyContent="center"
      margin-right="10px"
    >
      <button
        onClick={() => {
          navigate('/');
        }}
        style={{ color: colorStore.colorC }}
      >
        {t('pages.menu.homePage')}
      </button>
      <hr style={{ backgroundColor: colorStore.colorC }} width="90%" />

      <button
        onClick={() => {
          navigate('/my-configs');
        }}
        style={{ color: colorStore.colorC }}
      >
        {t('pages.menu.myConfigs')}
      </button>

      <hr style={{ backgroundColor: colorStore.colorC }} width="90%" />

      <button
        onClick={() => {
          navigate('/policy-privacy');
        }}
        style={{ color: colorStore.colorC }}
      >
        {t('pages.menu.privacyPolicy')}
      </button>

      <hr style={{ backgroundColor: colorStore.colorC }} width="90%" />

      <button
        onClick={() => {
          navigate('/contact');
        }}
        style={{ color: colorStore.colorC }}
      >
        {t('pages.menu.contact')}
      </button>

      <hr style={{ backgroundColor: colorStore.colorC }} width="90%" />

      <button
        onClick={() => {
          navigate('/language');
        }}
        style={{ color: colorStore.colorC }}
      >
        {t('pages.menu.language')}
      </button>
    </Grid>
  );
};

export default observer(NavMenu);
